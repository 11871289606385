import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
    background-color: darkblue;
    height: 150px;
    text-align: center;
`;

export const HeaderText = styled.h1`
margin: 0;
padding: 0;
padding-top: 30px;
color: white;
`;

export const Body = styled.div`
    margin: auto;
    max-width: 960px;
    margin-bottom: 50px;
`;