import React, { useEffect, useState } from 'react';
import * as C from './App.styles'
import { TableArea } from './components/TableArea';
import { InfoArea } from './components/InfoArea';

import { Item } from './types/item';
import { itens } from './data/itens';
import { categories } from './data/categories';
import { getCurrentMonth, filterListByMonth } from './helpers/dateFilter';
import { InputArea } from '../src/components/InputArea';


function App() {

  const [list, setList] = useState(itens);
  const [filteredList, setFilteredList] = useState<Item[]>([]);
  const [currentMonth, setCurrentMonth] = useState(getCurrentMonth());
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);


  useEffect(() => {

    setFilteredList(filterListByMonth(list, currentMonth))

  }, [list, currentMonth]);

  useEffect(() => {
    let income = 0;
    let expense = 0;

    for (let i in filteredList) {

      if (categories[filteredList[i].category].expense) {
        expense += filteredList[i].value;
      } else {
        income += filteredList[i].value;
      }
    }

    setIncome(income);
    setExpense(expense);

  }, [filteredList]);

  const handleMonthChange = (newMonth: string) => {
    setCurrentMonth(newMonth);
  }

  const handleAddItem = (item: Item) => {
    let newList = [...list];
    newList.push(item);
    setList(newList);
  }

  const handleRemoveItem = (item: Item) => {

    let newList = [...list];

    let index = newList.indexOf(item);

    newList.splice(index, 1);

    setList(newList);

  }

  return (
    <C.Container>
      <C.Header>
        <C.HeaderText>Sistema Financeiro</C.HeaderText>
      </C.Header>
      <C.Body>
        <InfoArea
          onMonthChange={handleMonthChange}
          currentMonth={currentMonth}
          income={income}
          expense={expense}
        />

        <InputArea onAdd={handleAddItem} />

        <TableArea list={filteredList} onRemove={handleRemoveItem} />
      </C.Body>
    </C.Container>

  );
}

export default App;