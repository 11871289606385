import { Item } from "../types/item";

export const getCurrentMonth = () => {
    let now = new Date();

    return `${now.getFullYear()}-${now.getMonth()}`;
};

export const filterListByMonth = (list: Item[], date: string): Item[] => {

    let newList: Item[] = [];

    let [year, month] = date.split('-');


    list.forEach(el => {
        
        if(
            el.date.getFullYear() === parseInt(year) &&
            (el.date.getMonth() + 1) === parseInt(month)
            ){
                newList.push(el);
            }
    });
    
    return newList;

};

export const formatDate = (date: Date): string => {

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();

    return `${addZeroToDate(day)}/${addZeroToDate(month+1)}/${year}`
};

const addZeroToDate = (n: number): string =>  n < 10 ? `0${n}`: `${n}`;

export const formatCurrentMonth = (currentMonth: string): string => {

    let [year, month] = currentMonth.split('-');

    let months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];

    return `${months[parseInt(month)-1]} de ${year}`
}