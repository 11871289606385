import * as C from './styles';
import { Item } from '../../types/item';
import {TableItem} from '../TableItem';


type Props = {
    list: Item[];
    onRemove: (item: Item) => void;
}

export const TableArea = ({list, onRemove}: Props) => {

    return(
        <C.Table>
            <thead>
                <tr>
                    <C.TableHeadColumn width={100} >Data</C.TableHeadColumn>
                    <C.TableHeadColumn width={130} >Categoria</C.TableHeadColumn>
                    <C.TableHeadColumn>Titulo</C.TableHeadColumn>
                    <C.TableHeadColumn width={150} >Valor</C.TableHeadColumn>
                    <C.TableHeadColumn width={50} >Action</C.TableHeadColumn>
                </tr>
            </thead>
            <tbody>
                {list.map((item, index)=>(
                       <TableItem key={index} item = {item} onRemove={onRemove}/>
                    ))
                }
            </tbody>
        </C.Table>
    )
}