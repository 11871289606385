import styled from "styled-components";

export const TableLine = styled.tr``;

export const TableColumn = styled.td`
    padding: 10px 0;
`;

export const Category = styled.div<{color: string}>`
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: #aaa;
    background-color: ${props => props.color};

`;

export const Button = styled.button`
    width: 100%;
    height: 30px;
    padding: 0 5px;
    border: 1px solid lightcoral;
    border-radius: 5px;
    background-color: lightcoral;
    color: black;
    cursor: pointer;
    &:hover {
        background-color: coral;
        color: white;}
`;

export const Value = styled.div<{color: string}>`
    color: ${props => props.color};
`;
