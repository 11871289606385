import * as C from './styles';
import {Item} from '../../types/item';
import {categories} from '../../data/categories';
import { useState } from 'react';
import {formatMoney, formatStoreMoney} from '../../helpers/formatNumbers'

type Props = {
    onAdd: (item: Item) => void;
}

export const InputArea = ({onAdd}: Props)=>{

    const [dateField, setDateField] = useState('');
    const [CategoryField, setcategoryField] = useState('');
    const [titleField, setTitleField] = useState('');
    const [valueField, setvalueField] = useState('');

    const keyCategoies: string[] = Object.keys(categories);

    const handleAddEvent = () => {
        let errors: string[] = [];

        if(isNaN(new Date(dateField).getTime())) {
          errors.push('Data inválida!');
        }
        if(!keyCategoies.includes(CategoryField)) {
          errors.push('Categoria inválida!');
        }
        if(titleField === '') {
          errors.push('Título vazio!');
        }
        if(valueField === '') {
          errors.push('Valor inválido!');
        }
    
        if(errors.length > 0) {
          alert(errors.join("\n"));
        } else {

          let valueClean = valueField.replace('.','').replace(',','');
          console.log(valueClean);
          let valueFloat = parseFloat(valueClean);

          onAdd({
            date: new Date(dateField),
            category: CategoryField,
            title: titleField,
            value: valueFloat
          });

          console.log(new Date(dateField))

          clearFields();
        }
      }
    
      const clearFields = () => {

        setDateField('');
        setcategoryField('');
        setTitleField('');
        setvalueField('');
      }

    return (
        <C.Container>
            
            <C.InputItens>
                <C.InputLabel>Data</C.InputLabel>
                <C.InputText value={dateField} type="date" onChange={ e => setDateField( e.target.value)}/>
            </C.InputItens>

            <C.InputItens>
            <C.InputLabel>Categoria</C.InputLabel>
            <C.Select value={CategoryField} onChange={ e => setcategoryField(e.target.value)} >
                <>
                <option></option>
                {
                    keyCategoies.map((key, index)=> (
                        <option key={index} value={key} >{ categories[key].title}</option>            
                    ))
                }
            </>
            </C.Select>
            </C.InputItens>

            <C.InputItens>
                <C.InputLabel>Titulo</C.InputLabel>
                <C.InputText value={titleField} type="text" onChange={ e => setTitleField(e.target.value)}/>
            </C.InputItens>

            <C.InputItens>
                <C.InputLabel>Valor</C.InputLabel>
                <C.InputText type="text" value={valueField} maxLength={14} onChange={e => setvalueField(formatMoney(e.target.value))} />
            </C.InputItens>

            <C.InputItens>
          <C.InputLabel>&nbsp;</C.InputLabel>
          <C.Button onClick={handleAddEvent}>Adicionar</C.Button>
        </C.InputItens>

        </C.Container>
    )
}