export const formatMoney = (value: any) => {

    value = value + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
        value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if (value.length > 10) {
        value = value.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");
    }


    if (value == 'NaN')
        value = '';


    return value;

}

export const formatStoreMoney = (value: any) => {
    value = value + '';
    value = value.replace('.', '');
    value = value.replace(',', '');

    return value;

}

export const formatViewMoney = (value: any) => {
    value = value + '';
    value = value.replace('.', ',');

    value =  formatMoney(value);

    return value;
}